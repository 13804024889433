<template>
  <er-card
    v-loading="loading"
    element-loading-background="white"
    ref="user-abw-graph"
    class="user-abw-chart"
  >
    <el-row :key="reRenderFunction()">
      <high-charts
        ref="highcharts"
        :options="chartOptions"
        constructor-type="stockChart"
      ></high-charts>
    </el-row>
  </er-card>
</template>

<script>
  // import { mapGetters } from "vuex";
  import errorHandlerMixin from "@/mixins/errorHandlerMixin";
  import filtersMixin from "@/mixins/filtersMixin";
  import { abwChanGraph } from "./chartOptions";
  import userPreferenceMixin from "@/mixins/userPreferenceMixin";

  export default {
    mixins: [errorHandlerMixin, filtersMixin, userPreferenceMixin],
    props: ['userPondsABWData'],
    data: function () {
      return {
        chartOptions: { ...abwChanGraph },
        loading: false,
        };
    },
    computed: {
      // ...mapGetters("superadmin", {
      //   getUserPondsABWData: "getUserPondsABWData"
      // }),
    },
    async mounted() {
      await this.initComponent();
    },
    watch: {
      userPondsABWData: {
        handler(value) {
          this.initComponent();
        },
        deep: true,
      },
    },
    methods: {
    reRenderFunction() {
      return (Math.random() * 10 + 10).toFixed(3);
    },
      async initComponent() {
        this.loading = true;
        try {
          await this.initChart();
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.loading = false;
        }
      },
    async initChart() {
      this.chartOptions.series = [];
        const abwSeriesData = this.userPondsABWData.map((ele) => ({
          x: ele.doc,
          y: ele.abw
        }));
        const farmBiomassData = this.userPondsABWData.map((ele) => ({
          x: ele.doc,
          y: ele.farm_biomass
        }));
        const sekrittingBiomassData = this.userPondsABWData.map((ele) => ({
          x: ele.doc,
          y: ele.skretting_biomass,
          percentage_with_farm_biomass: (ele.farm_biomass ?? 0) === 0 ? "" : `(${((ele.skretting_biomass / ele.farm_biomass) * 100).toFixed(2)}%)`
        }));
        const modelDataToModelNameMap = {
          feed_multiplier_model: "multiplierModel",
          'complex model-A': "CM_A_model",
          'complex model-N': "CM_N_model",
          'complex(EXP) model-N': "CM_EXP_N_model"
        }
        const { multiplierModelBiomassData, CM_A_modelBiomassData, CM_N_modelBiomassData, CM_EXP_N_modelBiomassData } = this.userPondsABWData.reduce((acc, curr) => {
          curr.estimations.forEach((estimation) => {
            acc[`${modelDataToModelNameMap[estimation.model]}BiomassData`].push({
              x: curr.doc,
              y: estimation?.value,
              percentage_with_farm_biomass: (curr.farm_biomass ?? 0) === 0 ? "" : `(${((estimation?.value / curr.farm_biomass ?? 1) * 100).toFixed(2)}%)`
            })
          })
          return acc;
        }, { multiplierModelBiomassData: [], CM_A_modelBiomassData: [], CM_N_modelBiomassData: [], CM_EXP_N_modelBiomassData: [] });
      // const multiplierModelBiomassData = this.userPondsABWData.map((ele) => {
      //     const multiplierModel = ele.estimations.find(item => item.model === "feed_multiplier_model");
      //     return {
      //       x: ele.doc,
      //       y: multiplierModel?.value
      //     };
      //   });
      // const CM_A_modelBiomassData = this.userPondsABWData.map((ele) => {
      //     const CM_A_model = ele.estimations.find(item => item.model === "complex model-A");
      //     return {
      //       x: ele.doc,
      //       y: CM_A_model?.value
      //     };
      //   });
      // const CM_N_modelBiomassData = this.userPondsABWData.map((ele) => {
      //     const CM_N_model = ele.estimations.find(item => item.model === "complex model-N");
      //     return {
      //       x: ele.doc,
      //       y: CM_N_model?.value
      //     };
      //   });
      // const CM_EXP_N_modelBiomassData = this.userPondsABWData.map((ele) => {
      //     const CM_EXP_N_model = ele.estimations.find(item => item.model === "complex(EXP) model-N");
      //     return {
      //       x: ele.doc,
      //       y: CM_EXP_N_model?.value
      //     };
      //   });
      this.chartOptions.series = [];
      [
        {
          name: "ABW (g)",
          type: "column",
          yAxis: 0,
          pointWidth: 30,
          data: abwSeriesData,
          tooltip: {
            valueSuffix: " g"
          },
          color: "#7394cb"
        },
        {
          name: "Farm Biomass (kg)",
          type: "spline",
          yAxis: 1,
          data: farmBiomassData,
          marker: {
            enabled: true
          },
          color: "#f79431"
        },
        {
          name: "Skretting Biomass (kg)",
          type: "spline",
          yAxis: 1,
          data: sekrittingBiomassData,
          marker: {
            enabled: true
          },
          color: "#541743"
        },
        {
          name: "Simple (kg)",
          type: "spline",
          yAxis: 1,
          data: multiplierModelBiomassData,
          marker: {
            enabled: true
          },
          color: "#299bf4"
        },
        {
          name: "Complex-A (kg)",
          type: "spline",
          yAxis: 1,
          data: CM_A_modelBiomassData,
          marker: {
            enabled: true
          },
          color: "#31ee0a"
        },
        {
          name: "Complex-N (kg)",
          type: "spline",
          yAxis: 1,
          data: CM_N_modelBiomassData,
          marker: {
            enabled: true
          },
          color: "#f31e94"
        },
        {
          name: "Complex(EXP)-N (kg)",
          type: "spline",
          yAxis: 1,
          data: CM_EXP_N_modelBiomassData,
          marker: {
            enabled: true
          },
          color: "#f10b0b"
        },
      ].forEach((seriesItem) => {
        this.chartOptions.series.push(seriesItem);
      });
      }
    },
  };
  </script>

  <style lang="scss">
    .market-chart {
      @include responsiveProperty(height, 66vh, 66vh, 50vh);
      &__container {
        height: 100%;
        width: 100%;
        &__highcharts {
          height: 100%;
          width: 100%;
        }
      }
    }
  </style>
