var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dialog-form',{attrs:{"model":_vm.getNewGateway(),"title":"Add Gateway","schema":_vm.gatewayFields,"showDialog":_vm.dialogVisible,"loading":_vm.dialogBoxLoading,"backendValidations":_vm.createGatewayFormErrors,"shouldErrorHighlightShow":true,"deviceType":"GATEWAY"},on:{"close-dialog":_vm.closeDialog,"save-details":_vm.handleSaveNewGateway,"clear-validations":_vm.handleClearValidations}}),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",staticClass:"is-filters-set-absolute",attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"tableProps":_vm.elTableProps,"addPadingToSearch":true,"search-property":"device_code","placeHolderForSearch":"Search_device_code","total":_vm.total,"button":true,"columnselector_require":true,"column-selector-options":{
      keyToStoreDefaults: 'gateway-details-tab',
      keyToDecideDefaultCol: 'required',
    },"selectedColumnHeadersSortable":true},on:{"cell-click":_vm.handleCellClick,"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddClick},scopedSlots:_vm._u([{key:"customer_name",fn:function(ref){
    var row = ref.row;
return [(row.data.user_data.length > 0)?[_vm._v("\n        "+_vm._s(_vm.customerFullName(row.data))+"\n      ")]:[_vm._v("--")]]}},{key:"lora_active",fn:function(ref){
    var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.data.settings.lora_active !== undefined ? row.data.settings.lora_active : true)+"\n    ")]}},{key:"ble_active",fn:function(ref){
    var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.data.settings.ble_active !== undefined ? row.data.settings.ble_active : false)+"\n    ")]}},{key:"wifi_active",fn:function(ref){
    var row = ref.row;
return [_vm._v("\n        "+_vm._s(row.data.settings.wifi_active !== undefined ? row.data.settings.wifi_active : false)+"\n    ")]}},{key:"customer_timezone",fn:function(ref){
    var row = ref.row;
return [(row.data.user_data.length > 0)?[_vm._v("\n        "+_vm._s(_vm.getCustomerTimezone(row.data))+"\n      ")]:[_vm._v("--")]]}},{key:"gateway_code",fn:function(ref){
    var row = ref.row;
return [_c('el-link',{attrs:{"href":("/admin/gateways/" + (row.data._id))}},[_vm._v("\n        "+_vm._s(row.data.code)+"\n      ")])]}},{key:"v1_id",fn:function(ref){
    var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.v1_id)+"\n      "+_vm._s(row.data.v1_code ? (" (" + (row.data.v1_code) + ")") : "")+"\n    ")]}},{key:"wifi_networks",fn:function(ref){
    var row = ref.row;
return [(_vm.wifiNetworks(row.data.settings).length > 0)?[_vm._l((_vm.wifiNetworks(row.data.settings)),function(network,index){return _c('el-tag',{key:index,attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(network))])}),_vm._v(" "),_c('br')]:[_vm._v("--")]]}},{key:"wifi_passwords",fn:function(ref){
    var row = ref.row;
return [(_vm.wifiPasswords(row.data.settings).length > 0)?[_vm._l((_vm.wifiPasswords(row.data.settings)),function(password,index){return _c('el-tag',{key:index,attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(password))])}),_vm._v(" "),_c('br')]:[_vm._v("--")]]}},{key:"wifi_security",fn:function(ref){
    var row = ref.row;
return [(_vm.wifiSecurity(row.data.settings).length > 0)?[_vm._l((_vm.wifiSecurity(row.data.settings)),function(security,index){return _c('el-tag',{key:index,attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(security))])}),_vm._v(" "),_c('br')]:[_vm._v("--")]]}},{key:"installed_date",fn:function(ref){
    var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.on_boarded_at)))+"\n    ")]}},{key:"v2_parsing_enabled",fn:function(ref){
    var row = ref.row;
return [_c('el-switch',{attrs:{"value":row.data.settings.v2_parsing ? row.data.settings.v2_parsing : false},on:{"input":function($event){return _vm.handleASettingKeyChange($event, row.data, 'v2_parsing')}}})]}},{key:"v1_parsing_enabled",fn:function(ref){
    var row = ref.row;
return [_c('el-switch',{attrs:{"value":row.data.settings.v1_parsing ? row.data.settings.v1_parsing : false},on:{"input":function($event){return _vm.handleASettingKeyChange($event, row.data, 'v1_parsing')}}})]}},{key:"last_communication",fn:function(ref){
    var row = ref.row;
return [(row.data.utc_last_communicated_at_status)?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(
            _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
          ))+"\n      ")]):[_vm._v(_vm._s(_vm.ftm__formatEmpty(
          _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
        )))]]}}])},[_c('template',{slot:"add-buttton"},[_vm._v("Add Gateways")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }