<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: stDetails
Description:this file contains the Schema that generate the form with default Values
-->
<template>
  <div class="shrimptalk-details">
    <el-card shadow="never">
      <h4 slot="header">Shrimptalk Settings</h4>
      <el-col :span="24">
        <generate-form
          v-loading="saveSettingsLoading"
          element-loading-background="white"
          :elFormOptions="elFormOptions"
          :schema="stFields"
          :model="modelData"
          ref="generatedForm"
          order-fields-by="DEFAULT"
          :disabled="isDisabled"
          @model-change="handleModelChange"
        >
          <template slot="footer">
            <el-row type="flex">
              <er-button
                btnType="save"
                :showLabel="true"
                :showIcon="true"
                size="mini"
                :disabled="isDisabled"
                @click="saveSettingsInDb"
              ></er-button>
              <er-button
                btnType="cancel"
                :showLabel="true"
                :showIcon="true"
                size="mini"
                :disabled="isDisabled"
                @click="initComponent()"
              ></er-button>
            </el-row>
          </template>
        </generate-form>
      </el-col>
    </el-card>

    <el-card>
      <template slot="header">
        <h4>Latest sensor values</h4>
      </template>
      <single-device-settings-table
        :key="device._id"
        :settings-details="device.sensor_values"
        :keys="stSensorKeys"
      ></single-device-settings-table>
    </el-card>
  </div>
</template>

<script>
import ShrimpTalk from "@/model/shrimptalk";
import singleDeviceSettingsTable from "@/components/superadmin/shared/singleDeviceSettingsTable";
import { mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import generateForm from "@/components/superadmin/shared/generateForm";

export default {
  props: {
    customerDetails: {
      default: () => ({}),
    },
    deviceDetails: { default: () => ({}) },
    isDisabled: { default: true },
  },
  mixins: [errorHandlerMixin],
  components: {
    singleDeviceSettingsTable,
    generateForm,
  },
  data: function () {
    return {
      payload: {},
      modelData: {},
      saveSettingsLoading: false,
    };
  },
  watch: {
    deviceDetails: function (newValue, oldValue) {
      this.initComponent();
    },
  },
  computed: {
    elFormOptions: function () {
      return {
        disabled: false,
        hideRequiredAsterisk: true,
        labelPosition: "top",
        layout: "grid",
        // inline: true
      };
    },
    device: function () {
      return this.deviceDetails || new ShrimpTalk();
    },
    wifi_table_data: function () {
      const settings = this.deviceDetails.settings || {};
      const wifi_networks = settings.wifi_networks || [];
      const wifi_password = settings.wifi_passwords;
      const wifi_security = settings.wifi_security;
      const wifi_signal = Array.isArray(settings.signal_strength)
        ? settings.signal_strength
        : [];

      const tableData = [];
      for (let i = 0; i < wifi_networks.length; i++) {
        tableData.push({
          wifi_network: wifi_networks[i],
          wifi_password: wifi_password[i],
          wifi_security: wifi_security[i],
          wifi_signal: wifi_signal[i] || "--",
        });
      }

      return tableData;
    },
    wifi_table_columns: function () {
      return [
        {
          prop: "wifi_network",
          label: "Wifi Network",
          required: true,
        },
        {
          prop: "wifi_password",
          label: "Wifi Password",
          required: true,
        },
        {
          prop: "wifi_security",
          label: "Wifi Security",
          required: true,
        },
        {
          prop: "wifi_signal",
          label: "Wifi Signal",
          required: true,
        },
      ];
    },
    stLabelKeys: function () {
      return {
        sleep_start: "sleep_start (H)",
        sleep_end: "sleep_end (H)",
      };
    },
    stFields() {
      return [
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "ST ID",
          model: "v1_code",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "IP Address",
          model: "ip_address",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Gateway IP Address",
          model: "gateway_ip_address",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "External IP Address",
          model: "external_ip_address",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Energy" },
              { value: 1, label: "Signatures" },
            ],
          },
          label: "Feeding Model",
          model: "feeding_model",
          validationRules: "",
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Feed Limit",
          model: "feed_limit",
          validationRules: "required|between:20,400",
        },
        {
          type: "timePicker",
          inputType: "time",
          label: "Start Time",
          model: "start_time",
          id: "start_time",
          validationRules: "required|timeLessThan:@end_time",
        },
        {
          type: "timePicker",
          inputType: "time",
          label: "Stop Time",
          model: "end_time",
          id: "end_time",
          validationRules: "required|timeGreaterThan:@start_time",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Normal" },
              { value: 1, label: "Passive" },
              { value: 2, label: "Active" },
            ],
          },
          label: "Gateway Role",
          model: "gateway_role",
          validationRules: "",
        },
        {
          type: "timePicker",
          inputType: "time",
          label: "Pause Start",
          model: "pause_start",
          id: "pause_start",
          validationRules: {
            required: true,
            validPauseStart: {
              start_time: "@start_time",
              pause_end: "@pause_end",
            },
          },
        },
        {
          type: "timePicker",
          inputType: "time",
          label: "Pause End",
          model: "pause_end",
          id: "pause_end",
          validationRules: {
            required: true,
            validPauseEnd: {
              end_time: "@end_time",
              pause_start: "@pause_start",
            },
          },
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Normal" },
              { value: 1, label: "Economical" },
              { value: 2, label: "Aggressive" },
              // { value: 3, label: " Super Aggressive" }
              { value: 4, label: "Advanced" }
            ],
          },
          label: "Feeding Level",
          model: "feeding_level",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "string",
          label: "Lora Channel",
          model: "lora_channel",
          validationRules: "required",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Latitude",
          model: "latitude",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Longitude",
          model: "longitude",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Auto" },
              { value: 1, label: "Short" },
              { value: 2, label: "Long" },
            ],
          },
          label: "Feed Gap Type",
          model: "fgi_type",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Feeder 1",
          model: "feeder1",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Feeder1 Gateway Id",
          model: "feeder1_gateway_id",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Feeder1 Gateway Channel",
          model: "feeder1_gateway_channel",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Feeder1 Gateway IP",
          model: "feeder1_gateway_ip",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Feeder 2",
          model: "feeder2",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Feeder2 Gateway Id",
          model: "feeder2_gateway_id",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "Feeder2 Gateway Channel",
          model: "feeder2_gateway_channel",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          label: "Feeder2 Gateway IP",
          model: "feeder2_gateway_ip",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "MAC Address",
          model: "mac_address",
          validationRules: "",
        },
        {
          type: "input",
          inputType: "text",
          props: { disabled: true },
          label: "OS Version",
          model: "os_version",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Software Version",
          model: "software_version",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Hardware Version",
          model: "hardware_version",
          validationRules: "",
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: false, min: 0 },
          label: "Start Index",
          model: "start_index",
          validationRules: "required",
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: false, min: 1, max: 10 },
          label: "Reset Index",
          model: "reset_index",
          validationRules: "required",
        },
        {
          type: "input_number",
          props: { disabled: false },
          inputType: "number",
          label: "Audio Card Gain",
          model: "audio_card_gain",
          validationRules: "required",
        },
        {
          type: "checkbox",
          props: { disabled: true },
          inputType: "boolean",
          label: "Audio Card AGC",
          model: "audio_card_agc",
          validationRules: "",
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: false },
          label: "AVG Feed Utilization",
          model: "avg_feed_utilization",
          validationRules: "required",
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: false, min: 60 },
          label: "Settings Interval (Secs)",
          model: "settings_interval",
          validationRules: "required",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "ShrimpTalk" },
              { value: 1, label: "Gateway" },
              { value: 2, label: "Both" },
            ],
          },
          inputType: "number",
          label: "Device Mode",
          model: "device_mode",
          validationRules: "",
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Service Restart",
          model: "service_restart",
          validationRules: "",
        },

        {
          type: "checkbox",
          inputType: "boolean",
          label: "Reboot",
          model: "pi_reboot",
          validationRules: "",
        },
        {
          type: "switch",
          props: { disabled: false },
          inputType: "boolean",
          label: "DO Threshold Enable",
          model: "pg_enable",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: "5000,10000", label: "5-10 kHz" },
              { value: "10000,20000", label: "10-20 kHz" },
            ],
          },
          label: "Energy Filters",
          model: "energy_filters",
          validationRules: "",
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Test Mode",
          model: "test_mode",
          validationRules: "",
        },
        {
          type: "checkbox",
          props: { disabled: true },
          inputType: "boolean",
          label: "Median Filter",
          model: "median_filter",
          validationRules: "",
        },
        {
          type: "input",
          props: { type: "textarea" },
          inputType: "text",
          label: "Group Settings",
          model: "group_settings",
          validationRules: { validFormat: { format: "JSON" } },
        },
        // 1. Feeding Behavior Controls
        {
          type: "input_number",
          props: { disabled: (this.modelData.feeding_level !== 4) },
          inputType: "number",
          label: "Aggressiveness",
          model: "aggressiveness",
          validationRules: "between:1,10"
        },
        {
          type: "input_number",
          props: { disabled: (this.modelData.feeding_level !== 4) },
          inputType: "number",
          label: "Max Feed Duration (mins)",
          model: "max_feed_duration",
          validationRules: "",
        },
        {
          type: "timePicker",
          props: { disabled: (this.modelData.feeding_level !== 4) },
          inputType: "time",
          label: "Low DO Start Time",
          model: "low_do_start_times",
          id: "low_do_start_times",
          validationRules: ""
        },
        {
          type: "timePicker",
          props: { disabled: (this.modelData.feeding_level !== 4) },
          inputType: "time",
          label: "Low DO End Time",
          model: "low_do_end_times",
          id: "low_do_end_times",
          validationRules: ""
        },
        {
          type: "timePicker",
          props: { disabled: (this.modelData.feeding_level !== 4) },
          inputType: "time",
          label: "High Temp Start Time",
          model: "high_temp_start_times",
          id: "high_temp_start_times",
          validationRules: ""
        },
        {
          type: "timePicker",
          props: { disabled: (this.modelData.feeding_level !== 4) },
          inputType: "time",
          label: "High Temp End Time",
          model: "high_temp_end_times",
          id: "high_temp_end_times",
          validationRules: ""
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Custom Safe Feed (kg/hr)",
          model: "custom_safe_feed",
          validationRules: "",
        },
        {
          type: "switch",
          inputType: "boolean",
          label: "Rain Safe Enabled",
          model: "rain_safe_enabled",
          validationRules: "",
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Low DO (mg/L)",
          model: "low_do",
          validationRules: "min_value:0",
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Critical Low DO (mg/L)",
          model: "critical_low_do",
          validationRules: "min_value:0",
        },
        {
          type: "input_number",
          inputType: "number",
          label: "High Temp (°C)",
          model: "high_temp",
          validationRules: "min_value:0",
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Critical High Temp (°C)",
          model: "critical_high_temp",
          validationRules: "min_value:0",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Inactive" },
              { value: 1, label: "Active" },
              { value: 2, label: "Control" },
            ],
          },
          label: "Hydrophone Disconnect State",
          model: "hydro_disconnected_state",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Inactive" },
              { value: 1, label: "Active" },
              { value: 2, label: "Control" },
            ],
          },
          label: "Custom Safe Feed State",
          model: "custom_safe_feed_state",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Inactive" },
              { value: 1, label: "Active" },
              { value: 2, label: "Control" },
            ],
          },
          label: "Rain Safe State",
          model: "rain_safe_state",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Inactive" },
              { value: 1, label: "Active" },
              { value: 2, label: "Control" },
            ],
          },
          label: "DO Feeding State",
          model: "do_feeding_state",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Inactive" },
              { value: 1, label: "Active" },
              { value: 2, label: "Control" },
            ],
          },
          label: "Temperature Feeding State",
          model: "temp_feeding_state",
          validationRules: "",
        },
        {
          type: "select",
          props: {
            options: [
              { value: 0, label: "Inactive" },
              { value: 1, label: "Active" },
              { value: 2, label: "Control" },
            ],
          },
          label: "Improved Safe Feeding State",
          model: "improved_safe_feeding_state",
          validationRules: "",
        }
      ];
    },
    stSettingsObj: function () {
      return {
        feed_limit: 70,
        feeder1: "",
        feeder2: "",
        settings_interval: 300,
        service_restart: false,
        lora_channel: "916000000",
        feeder1_gateway_id: "",
        feeder1_gateway_channel: "",
        feeder1_gateway_ip: "0.0.0.0",
        feeder2_gateway_id: "",
        feeder2_gateway_channel: "",
        feeder2_gateway_ip: "0.0.0.0",
        pi_reboot: false,
        pause_start: "00:00:00",
        pause_end: "00:00:00",
        start_time: "00:06:00",
        end_time: "00:20:00",
        start_index: 3,
        reset_index: 2,
        mac_address: "",
        os_version: "",
        audio_card_gain: 12,
        audio_card_agc: true,
        avg_feed_utilization: 0,
        median_filter: true,
        test_mode: false,
        device_mode: 0,
        gateway_role: 0,
        external_ip_address: "0.0.0.0",
        ip_address: "0.0.0.0",
        gateway_ip_address: "0.0.0.0",
        feeding_level: undefined,
        feeding_model: 0,
        energy_filters: "",
        fgi_type: undefined,
        latitude: "0",
        longitude: "0",
        mac_id: "",
        software_version: "",
        hardware_version: "",
        ip_address_external: "",
        pg_enable: false,
        group_settings: "{}",
        aggressiveness: 2,
        max_feed_duration: 75,
        low_do_start_times: "00:00:00",
        low_do_end_times: "00:00:00",
        high_temp_start_times: "00:00:00",
        high_temp_end_times: "00:00:00",
        custom_safe_feed: 0,
        rain_safe_enabled: false,
        low_do: 0,
        critical_low_do: 0,
        high_temp: 0,
        critical_high_temp: 0,
        hydro_disconnected_state: 0,
        custom_safe_feed_state: 0,
        rain_safe_state: 0,
        do_feeding_state: 0,
        temp_feeding_state: 0,
        improved_safe_feeding_state: 0,
      };
    },
    stSensorKeys() {
      return {
        date: "string",
        battery_voltage: "number",
        utc_date: "string",
      };
    },
  },
  methods: {
    ...mapActions("superadmin", {
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData",
    }),
    initComponent() {
      this.modelData = this.$lodash.cloneDeep({
        v1_code: `ST${(this.device || { v1_code: "NO CODE" }).v1_code}`,
        ...this.stSettingsObj,
        ...(this.device || { settings: {} }).settings,
      });
      if (this.modelData && this.modelData.group_settings) {
        this.modelData.group_settings = [
          [/(?<=[a-zA-Z])'(?=[a-zA-Z ])/g, "__"],
          [/'/gi, '"'],
          [/None/g, "null"],
          [/False(?=([^"\\]*(\\.|"([^"\\]*\\.)*[^"\\]*"))*[^"]*$)/gi, "false"],
          [/True(?=([^"\\]*(\\.|"([^"\\]*\\.)*[^"\\]*"))*[^"]*$)/gi, "true"],
        ].reduce((acc, curr) => {
          acc = acc.replaceAll(curr[0], curr[1]);
          return acc;
        }, this.modelData.group_settings);
        console.log(this.modelData.group_settings);
        this.modelData.group_settings = JSON.stringify(
          JSON.parse(this.modelData.group_settings),
          null,
          2
        );
      }
      this.modelData.energy_filters = (
        this.modelData.energy_filters || []
      ).join(",");

       // Extract first elements from specific arrays
      const fieldsToExtract = [
        "low_do_start_times",
        "low_do_end_times",
        "high_temp_start_times",
        "high_temp_end_times"
      ];

      fieldsToExtract.forEach((field) => {
        if (Array.isArray(this.modelData[field])) {
          let timeValue = this.modelData[field][0] || "00:00:00"
          // Append seconds if time is in HH:MM format
          if (/^\d{2}:\d{2}$/.test(timeValue)) {
            timeValue += ":00"
          }
          this.modelData[field] = timeValue;
        }
      });
      console.log(this.modelData)
    },
    conversion(value) {
      const roundPadd = (value) => ("" + Math.round(value)).padStart(2, "0");
      return (
        roundPadd(value / 3600) +
        ":" +
        roundPadd((value % 3600) / 60) +
        ":" +
        roundPadd((value % 21600) / 60)
      );
    },

    conversionforBackend(str) {
      return str.split(":").reduce((acc, curr, index) => {
        if (index < 2) {
          acc += +curr * Math.pow(60, 3 - index - 1);
        } else {
          acc += +curr;
        }
        return acc;
      }, 0);
    },
    handleModelChange(value, path) {
      this.modelData[path] = value;
      this.payload = Object.assign({}, this.modelData);
    },
    async saveSettingsInDb() {
      const response =
        await this.$refs.generatedForm.$refs.generatedFormValidator.validate();
      if (!response) {
        return;
      }
      await this.$confirm(
        "These changes will effect the behaviour of the device, Are you sure?",
        "Warning",
        {
          confirmButtonText: "Save",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning",
        }
      );
        // Convert fields to arrays if they're not already
      const toArray = (val) => (Array.isArray(val) ? val : [val]);
      this.saveSettingsLoading = true;
      this.payload.shrimp_talk_id = this.deviceDetails._id;
      this.payload.energy_filters = this.payload.energy_filters
        .split(",")
        .map((x) => +x);
      // Time fields that should always be arrays
      this.payload.low_do_start_times = toArray(this.payload.low_do_start_times);
      this.payload.low_do_end_times = toArray(this.payload.low_do_end_times);
      this.payload.high_temp_start_times = toArray(this.payload.high_temp_start_times);
      this.payload.high_temp_end_times = toArray(this.payload.high_temp_end_times);
      try {
        const response = await this.saveSingleDeviceSettingsData({
          device_code: this.deviceDetails.code,
          payload: this.payload,
          settingsType: "SHRIMP_TALK",
        });
        await this.$emit("refresh", this.device._id);
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success",
        });
      } catch (err) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success",
        });
        this.ehm__errorMessages(err, true);
      }
      this.saveSettingsLoading = false;
    },
  },
};
</script>

<style lang="scss">
.shrimptalk-details {
  margin-top: 10px;
  .el-card {
    padding-bottom: 10px;
  }
  div.cell {
    text-transform: capitalize;
  }
  .el-form {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px;
    .form-cell {
      width: 200px;
    }
  }
}
</style>
