var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"shrimp-snap-table-sa",attrs:{"no-aside":true}},[_c('tab-layout',{attrs:{"componentList":_vm.componentList,"showComponent":_vm.urlParams.tab,"tab-click-handler":_vm.handleTabClick},on:{"selectedComponent":_vm.handleSelectedComponent}},[_c('template',{slot:"shrimpSnapDetails"},[_c('dialog-form',{attrs:{"model":_vm.getNewShrimpSnap(),"schema":_vm.shrimpsnapFields,"showDialog":_vm.dialogVisible,"loading":_vm.dialogBoxLoading,"backendValidations":_vm.createShrimpSnapFormErrors,"title":"Add ShrimpSnap","v1-code-length":6,"deviceType":"SHRIMP_SNAP","shouldErrorHighlightShow":true},on:{"close-dialog":_vm.closeDialog,"save-details":_vm.handleSaveNewShrimpSnap,"clear-validations":_vm.handleClearValidations}}),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",attrs:{"tableProps":_vm.elTableProps,"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"addPadingToSearch":true,"search-property":"device_code","placeHolderForSearch":"Search_device_code","total":_vm.total,"button":true,"columnselector_require":true,"column-selector-options":{
          keyToStoreDefaults: 'shrimpsnap-table-sa',
          keyToDecideDefaultCol: 'required',
        },"selectedColumnHeadersSortable":true},on:{"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddClick},scopedSlots:_vm._u([{key:"shrimpsnap_id",fn:function(ref){
        var row = ref.row;
return [_c('el-link',{attrs:{"href":("/admin/shrimpsnaps/" + (row.data._id))}},[_vm._v("\n            SS"+_vm._s(row.data.v1_code)+"\n          ")])]}},{key:"hex_id",fn:function(ref){
        var row = ref.row;
return [_c('el-link',{attrs:{"href":("/admin/shrimpsnaps/" + (row.data._id))}},[_vm._v("\n            "+_vm._s(row.data.code)+"\n          ")])]}},{key:"user_details",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.user_id ? row.data.user_details.full_name : "--")+"\n        ")]}},{key:"dealer_details",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.user_id ? row.data.dealer_details.full_name : "--")+"\n        ")]}},{key:"location_details",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.user_id ? row.data.location_details.name : "--")+"\n        ")]}},{key:"last_communication",fn:function(ref){
        var row = ref.row;
return [(row.data.utc_last_communicated_at_status)?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v("\n            "+_vm._s(_vm.ftm__formatEmpty(
                _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
              ))+"\n          ")]):[_vm._v("\n            "+_vm._s(_vm.ftm__formatEmpty(
                _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
              ))+"\n          ")]]}},{key:"installed_date",fn:function(ref){
              var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.on_boarded_at)))+"\n        ")]}}])},[_c('template',{slot:"add-buttton"},[_vm._v("Add ShrimpSnap")])],2)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }