<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondGuardTableSA.vue
Description:This file contains the latest  details of the pondguard devices
-->
<template>
  <settings-table
    ref="erDataTables"
    v-loading="loading"
    element-loading-background="white"
    :tableData="tableData"
    :columns="latestSensorDataColumnsObject"
    :total="total"
    :button="false"
    :tableProps="elTableProps"
    :addPadingToSearch="true"
    :columnselector_require="true"
    :column-selector-options="{
        keyToStoreDefaults: 'pg-latest-values',
        keyToDecideDefaultCol: 'required',
    }"
    :selectedColumnHeadersSortable="true"
    search-property="device_code"
    placeHolderForSearch="Search_device_code"
    @reload="handleLoadData"
    @loadData="handleLoadData"
    @cell-click="handleCellClick"
    @cleared="handleLoadData"
    class="is-filters-set-absolute"
  >
    <template v-slot:pondguard_code="{ row }">
      <el-link :href="`/admin/pondguards/${row.data._id}`">
        {{ row.data.code }}
      </el-link>
    </template>
    <template v-slot:installed_date="{ row }">
      {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.on_boarded_at)) }}
    </template>

    <template v-slot:last_communication="{ row }">
      <el-tag
        size="mini"
        type="success"
        effect="dark"
        v-if="row.data.utc_last_communicated_at_status"
      >
        {{
          ftm__formatEmpty(
            adm__filterToFormatDate(row.data.utc_last_communicated_at)
          )
        }}
      </el-tag>
      <template v-else>{{
        ftm__formatEmpty(
          adm__filterToFormatDate(row.data.utc_last_communicated_at)
        )
      }}</template>
    </template>
  </settings-table>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminChartsMixin from "@/mixins/adminChartsMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import { mapActions } from "vuex";
export default {
  mixins: [
    errorHandlerMixin,
    adminChartsMixin,
    filtersMixin,
    adminDatesHandlerMixin
  ],
  components: { settingsTable },
  data: function() {
    return {
      tableData: [],
      total: 0,
      loading: false
    };
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      fetchAllSensorDeviceDetails: "fetchAllSensorDeviceDetails",
      fetchAllLessDoValues: "fetchAllLessDoValues"
    }),
    async handleLoadData(query) {
      try {
        this.loading = true;
        const response = await this.fetchAllSensorDeviceDetails({
          device_type: "pond_guard",
          infinite_scroll: false,
          params: query
        });
        this.tableData = response.pond_guards;
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.erDataTables) {
            console.log(this.$refs.erDataTables.$refs.dataTable.$refs.elTable);
            this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    handleCellClick() {}
  },
  computed: {
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 150px)";
    },

    latestSensorDataColumnsObject: function() {
      return [
        {
          prop: "code",
          label: this.$t("Comn_pond_guard_code"),
          required: true
        },
        {
          prop: "sensor_values.dissolved_oxygen",
          label: "Dissolved Oxygen",
          required: true
        },
        {
          prop: "sensor_values.ph",
          label: "pH",
          required: true
        },
        {
          prop: "sensor_values.temperature",
          label: "Temperature",
          required: true
        },
        {
          prop: "sensor_values.solar_voltage",
          label: "Solar Voltage",
          required: true
        },
        {
          prop: "sensor_values.load_current",
          label: "Load Current",
          required: true
        },
        {
          prop: "sensor_values.charging_current",
          label: "Charging Current",
          required: true
        },
        {
          prop: "sensor_values.latitude",
          label: "Latitude",
          required: false
        },
        {
          prop: "sensor_values.longitude",
          label: "Longitude",
          required: false
        }
      ];
    }
  }
};
</script>

<style></style>
