var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{staticClass:"gateway-table-sa",attrs:{"no-aside":true}},[_c('tab-layout',{attrs:{"componentList":_vm.componentList,"showComponent":_vm.urlParams.tab,"tab-click-handler":_vm.handleTabClick},on:{"selectedComponent":_vm.handleSelectedComponent}},[_c('template',{slot:"feedBlowerDetails"},[_c('dialog-form',{attrs:{"model":_vm.getNewFeedBlower(),"schema":_vm.gatewayFields,"showDialog":_vm.dialogVisible,"loading":_vm.dialogBoxLoading,"backendValidations":_vm.createFeedBlowerFormErrors,"title":"Add Feed Blower"},on:{"close-dialog":_vm.closeDialog,"save-details":_vm.handleSaveNewFeedBlower,"clear-validations":_vm.handleClearValidations}}),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",attrs:{"tableProps":_vm.elTableProps,"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"addPadingToSearch":true,"search-property":"device_code","placeHolderForSearch":"Search_device_code","total":_vm.total,"button":true,"columnselector_require":true,"column-selector-options":{
            keyToStoreDefaults: 'fb-table-sa',
            keyToDecideDefaultCol: 'required',
        },"selectedColumnHeadersSortable":true},on:{"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddClick},scopedSlots:_vm._u([{key:"feed_blower_code",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.code)+"\n          ")]}},{key:"customer",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.user_details ? row.data.user_details.full_name : "--")+"\n        ")]}},{key:"dealer_details",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.dealer_details ? row.data.dealer_details.full_name : "--")+"\n        ")]}},{key:"location_details",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(row.data.location_details ? row.data.location_details.name : "--")+"\n        ")]}},{key:"installed_date",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.on_boarded_at)))+"\n        ")]}}])},[_c('template',{slot:"add-buttton"},[_vm._v("Add Feed Blower")])],2)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }