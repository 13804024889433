<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: PondGUardDeviceDetails
Description:This file contains the details of the pondguard devices
-->
<template @res>
  <div>
    <dialog-form
      :model="getNewPondGuard()"
      :schema="pondGuardFormFields"
      :showDialog="dialogVisible"
      :loading="dialogBoxLoading"
      title="Add PondGuard"
      :backendValidations="createPGFormErrors"
      @close-dialog="closeDialog"
      @save-details="handleSaveNewPondguard"
      @clear-validations="handleClearValidations"
      deviceType="POND_GUARD"
      :shouldErrorHighlightShow="true"
    ></dialog-form>

    <settings-table
      ref="erDataTables"
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :total="total"
      :tableProps="elTableProps"
      :addPadingToSearch="true"
      :button="true"
      :columnselector_require="true"
      :column-selector-options="{
        keyToStoreDefaults: 'pg-device-details',
        keyToDecideDefaultCol: 'required',
      }"
      :selectedColumnHeadersSortable="true"
      search-property="device_code"
      placeHolderForSearch="Search_device_code"
      @reload="handleLoadData"
      @loadData="handleLoadData"
      @cell-click="handleCellClick"
      @add-item-click="handleAddClick"
      class="is-filters-set-absolute"
    >
      <template slot="add-buttton">Add PondGuard</template>
      <template v-slot:pondguard_code="{ row }">
        <router-link :to="`/admin/pondguards/${row.data._id}`">{{
          row.data.code
        }}</router-link>
      </template>
      <template v-slot:v1_id="{ row }">
        {{ row.data.v1_id }}
        {{ row.data.v1_code ? ` (${row.data.v1_code})` : "" }}
      </template>
      <template v-slot:gateway_code="{ row }">
        {{ row.data.sensor_values ? row.data.sensor_values.gateway_code : "-" }}
      </template>
      <template v-slot:installed_date="{ row }">
        {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.on_boarded_at)) }}
      </template>

      <template v-slot:user_details="{ row }">
        {{
          ftm__objectValueByPath(
            row.data.user_details,
            "full_name",
            ftm__formatEmpty("--")
          )
        }}
      </template>

      <template v-slot:last_communication="{ row }">
        <el-tag
          size="mini"
          type="success"
          effect="dark"
          v-if="row.data.utc_last_communicated_at_status"
        >
          {{
            ftm__formatEmpty(
              adm__filterToFormatDate(row.data.utc_last_communicated_at)
            )
          }}
        </el-tag>
        <template v-else>{{
          ftm__formatEmpty(
            adm__filterToFormatDate(row.data.utc_last_communicated_at)
          )
        }}</template>
      </template>
      <template v-slot:pond_status="{ row }">
        <el-tag
          size="mini"
          type="success"
          effect="dark"
          v-if="row.data.pond_details && row.data.pond_details.status === 'ACTIVE'"
        >
          {{
            ftm__formatEmpty(
              'Active'
            )
          }}
        </el-tag>
        <template v-else-if="row.data.pond_details && row.data.pond_details.status === 'INACTIVE'">{{
          ftm__formatEmpty(
            'Inactive'
          )
        }}</template>
        <template v-else>{{
          ftm__formatEmpty(
            '--'
          )
        }}</template>
      </template>
    </settings-table>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import { mapActions } from "vuex";
import dialogForm from "@/components/superadmin/shared/dialogForm";
export default {
  mixins: [errorHandlerMixin, filtersMixin, adminDatesHandlerMixin],
  data: function() {
    return {
      tableData: [],
      total: 0,
      loading: false,
      dialogVisible: false,
      createPGFormErrors: {},
      dialogBoxLoading: false
    };
  },
  components: {
    settingsTable,
    dialogForm
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      fetchAllSensorDeviceDetails: "fetchAllSensorDeviceDetails",
      fetchAllLessDoValues: "fetchAllLessDoValues"
    }),
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        this.clearDeviceDetails({
          device_type: "pond_guard"
        });
        // this.$router.push({
        //   name: "PondGuardDetails",
        //   params: { pg_id: row._id }
        // });
      }
    },
    async handleDeviceClick(event, { device_code, device_type }) {
      const deviceRouteStr = {
        gateway: "/admin/gateways/",
        pond_mother: "/admin/pondmothers/",
        pond_guard: "/admin/pondguards/",
        shrimp_talk: "/admin/shrimptalks/"
      };
      const responseDeviceKey = {
        gateway: "gateways",
        pond_mother: "pond_mothers",
        pond_guard: "pond_guards",
        shrimp_talk: "shrimp_talks"
      };
      const response = await this.fetchAllDeviceDetails({
        device_type: device_type,
        params: { device_code: device_code }
      });
      this.clearDeviceDetails({
        device_type: device_type
      });
      this.$router.push(
        `${deviceRouteStr[device_type]}${
          response[responseDeviceKey[device_type]][0]._id
        }`
      );
    },
    closeDialog() {
      this.dialogVisible = false;
      this.createPGFormErrors = {};
    },
    handleAddClick() {
      this.dialogVisible = true;
    },

    async handleLoadData(query) {
      try {
        this.loading = true;
        const response = await this.fetchAllSensorDeviceDetails({
          device_type: "pond_guard",
          infinite_scroll: false,
          params: query
        });
        this.tableData = response.pond_guards;
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.refreshTableLayout();
        this.loading = false;
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          console.log(this.$refs.erDataTables.$refs.dataTable.$refs.elTable);
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    async handleSaveNewPondguard(model, arrNames) {
      try {
        this.dialogBoxLoading = true;
        const payload = arrNames.map((name) => {
          return {
            v1_code: name,
            on_boarded_at: model.on_boarded_at,
            do_alerts_config: [
              {
                start_time: "00:00:00",
                end_time: "23:59:59",
                critical_lower_limit: model.do_critical_lower_limit,
                lower_limit: model.do_lower_limit
              }
            ],
            temperature_alerts_config: [
              {
                start_time: "00:00:00",
                end_time: "23:59:59",
                upper_limit: model.temp_upper_limit,
                lower_limit: model.temp_lower_limit
              }
            ],
            settings: {
              lcd_sleep_mode: model.lcd_sleep_mode,
              lcd_sleep_interval: model.lcd_sleep_interval * 60,
              temperature_sensor_control: model.temperature_sensor_control,
              temperature_correction_factor:
                model.temperature_correction_factor,
              ph_correction_factor: model.ph_correction_factor,
              do_correction_factor: model.do_correction_factor
            }
          };
        });
        await this.saveListDevicesData({ device_type: "POND_GUARD", payload });
        this.$notify({
          title: "Success",
          message: "Created PondGuards Successfully",
          type: "success"
        });
        this.dialogVisible = false;
      } catch (err) {
        err.response.data.errors.details.forEach(async (error) => {
          this.notifyFun(error.message);
        });
        if (err.response && err.response.data) {
          this.createPGFormErrors = err.response.data.errors.details.reduce(
            (acc, err) => {
              acc[err.key] = err.message;
              return acc;
            },
            {}
          );
        }
      } finally {
        this.dialogBoxLoading = false;
      }
    },
    async notifyFun(err_msg) {
      await new Promise(resolve => setTimeout(resolve, 600));
      await this.$notify({
          title: "Failed",
          message: err_msg,
          type: "error",
          duration: 5000,
        });
    },
    handleClearValidations(type, indexPosition) {
      switch (type) {
        case "ALL":
          this.createPGFormErrors = {};
          break;
        case "SINGLE":
          delete this.createPGFormErrors[indexPosition];
          break;
      }
    },
    getNewPondGuard() {
      return {
        on_boarded_at: this.adm__dateUtilsLib.formatDate(
          new Date(),
          "yyyy-MM-dd"
        ),
        lcd_sleep_mode: true,
        lcd_sleep_interval: 3,
        temperature_sensor_control: true,
        temperature_correction_factor: 0,
        ph_correction_factor: 0,
        do_correction_factor: 0,
        do_critical_lower_limit: 1.5,
        do_lower_limit: 2.5,
        temp_upper_limit: 30,
        temp_lower_limit: 25,
        group_settings: "{}"
      };
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (this.$refs.erDataTables) {
        this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
      }
    });
  },
  computed: {
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 150px)";
    },

    pondGuardFormFields: function() {
      return [
        {
          type: "datePicker",
          inputType: "string",
          label: "Installation Date",
          model: "on_boarded_at",
          validationRules: "required"
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "LCD Sleep Mode",
          model: "lcd_sleep_mode",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "Lcd Sleep Interval (mins)",
          model: "lcd_sleep_interval",
          validationRules: "required"
        },
        {
          type: "checkbox",
          inputType: "boolean",
          props: { min: 0 },
          label: "Temp Sensor Control",
          model: "temperature_sensor_control",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "Temp Correction Factor",
          model: "temperature_correction_factor",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "ph Correction Factor",
          model: "ph_correction_factor",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "DO Correction Factor",
          model: "do_correction_factor",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "Critical DO (mg/L)",
          model: "do_critical_lower_limit",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "Low DO (mg/L)",
          model: "do_lower_limit",
          validationRules: "required|"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "Time Upper Limit (\u00b0C)",
          model: "temp_upper_limit",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "Time Lower Limit (\u00b0C)",
          model: "temp_lower_limit",
          validationRules: "required"
        }
      ];
    },
    columnsObject: function() {
      return [
        {
          prop: "code",
          label: "PondGuard Code",
          required: true,
          sortable: true,
          minWidth: 150,
          fixed: "fixed"
        },
        {
          prop: "title",
          label: this.$t("Alias"),
          required: true,
          minWidth: 100
        },
        {
          prop: "user_details.full_name",
          label: "Customer Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "user_details.country.name",
          label: "Country",
          required: true,
          minWidth: 120
        },
        {
          prop: "user_details.timezone.name",
          label: "Customer Timezone",
          required: true,
          minWidth: 200
        },
        {
          prop: "device_timezone",
          label: "Device Timezone",
          required: true,
          minWidth: 200
        },
        {
          prop: "pond_details.title",
          label: "Pond Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "pond_details.status",
          label: "Pond Status",
          required: true,
          minWidth: 200
        },
        {
          prop: "dealer_details.full_name",
          label: "Dealer Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "location_details.name",
          label: "Location",
          required: true,
          minWidth: 200
        },

        {
          prop: "v1_id",
          label: "V1 Id",
          required: false,
          minWidth: 150
        },
        {
          prop: "_id",
          label: "V2 Id",
          required: false,
          minWidth: 200
        },
        {
          prop: "sensor_values.gateway_code",
          label: "Gateway code",
          sortable: true,
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.data_interval",
          label: "Data Interval",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.sampling_interval",
          label: "Sampling Interval",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.software_version",
          label: "Software Version",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.hardware_version",
          label: "Hardware Version",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.do_calibration_value",
          label: "DO Calibration Value",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph4_calibration_value",
          label: "ph4 Calibration Value",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph7_calibration_value",
          label: "ph7 Calibration Value",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph9_calibration_value",
          label: "ph9 Calibration Value",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph4_temperature_value",
          label: "ph4 Temperature Value",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.diagnose_interval",
          label: "Diagnose Interval",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.data_reset_interval",
          label: "Data Reset Interval",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph_sensor_control",
          label: "ph Sensor Control",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph_slope",
          label: "ph Slope",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.temperature_sensor_control",
          label: "Temperature Sensor Control",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph7_temperature_value",
          label: "ph7 Temperature Value",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph9_temperature_value",
          label: "ph9 Temperature Value",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.temperature_correction_factror",
          label: "Temperature Correction Factor",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.do_correction_factor",
          label: "DO Correction Factor",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.ph_correction_factor",
          label: "ph Correction Factor",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.lcd_sleep_mode",
          label: "lcd Sleep Mode",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.lcd_sleep_interval",
          label: "LCD Sleep Interval",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.ph_on_interval",
          label: "ph on Interval",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.self_cleaning_threshold_voltage",
          label: "Self Cleaning Threshold Voltage",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.self_cleaning_interval",
          label: "Self Cleaning Interval",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.self_clean_on_time",
          label: "Self Clean on Time",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.salinity",
          label: "Salinity",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.pressure",
          label: "Pressure",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.pond_depth",
          label: "Pond Depth",
          required: false,
          minWidth: 100
        },
        {
          prop: "settings.distance_from_water_surface",
          label: "Distance From Water Surface",
          required: false,
          minWidth: 100
        },
        {
          prop: "on_boarded_at",
          label: "Installed date",
          required: false,
          type: "date",
          sortable: true,
          minWidth: 100
        },
        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          sortable: "custom",
          type: "date",
          minWidth: 120
        }
      ];
    }
  }
};
</script>

<style></style>
