/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: chartOptions.js
Description: This file contains the highcharts configurations for charts in the ponds page
*/
// import Highcharts from "highcharts";
import { CHART_DATE_FORMATS } from "@/utils/commonUtils";
import { baseChartConfig as baseGraphConfig } from "@/config/baseChartConfig";

const exporting = function(enabled) {
  return {
    enabled: enabled
  };
};
const legend = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled
  };
};
const title = function(enabled) {
  return {
    enabled: enabled,
    useHTML: enabled,
    text: undefined
  };
};
const tooltip = {
  default: {
    shared: true,
    useHTML: true,
    headerFormat: "<small>{point.key}</small><table>",
    pointFormat:
      '<tr><td style="color: {series.color}">{series.name}: </td>' +
      '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false,
    xDateFormat: CHART_DATE_FORMATS.dmy
  },
  numericXAxisToolTip: {
    shared: true,
    useHTML: true,
    headerFormat: "<small><strong>DOC:{point.x}</strong></small><table>",
    pointFormatter: function() {
      const seriesNameToValueFormat = {
        "ABW (g)": `<td style="text-align: right"><b>${!this.y ? "--" : `${this.y}`}</b></td>`,
        "Farm Biomass (kg)": `<td style="text-align: right"><b>${!this.y ? "--" : `${this.y}`}</b></td>`,
        "Skretting Biomass (kg)": `<td style="text-align: right"><b>${!this.y ? "--" : `${this.y} ${this.percentage_with_farm_biomass}`}</b></td>`,
        "Simple (kg)": `<td style="text-align: right"><b>${!this.y ? "--" : `${this.y} ${this.percentage_with_farm_biomass}`}</b></td>`,
        "Complex-A (kg)": `<td style="text-align: right"><b>${!this.y ? "--" : `${this.y} ${this.percentage_with_farm_biomass}`}</b></td>`,
        "Complex-N (kg)": `<td style="text-align: right"><b>${!this.y ? "--" : `${this.y} ${this.percentage_with_farm_biomass}`}</b></td>`,
        "Complex(EXP)-N (kg)": `<td style="text-align: right"><b>${!this.y ? "--" : `${this.y} ${this.percentage_with_farm_biomass}`}</b></td>`,
      }
      return `<tr><td style="color: ${this.series.color}">${this.series.name}: </td> ${seriesNameToValueFormat[this.series.name]}
        </tr>`
    },
    footerFormat: "</table>",
    valueDecimals: 2,
    split: false
  }
};
const abwChanGraph = {
  ...baseGraphConfig,
  legend: legend(true),
  exporting: exporting(false),
  title: title(false),
  tooltip: {
    ...tooltip.numericXAxisToolTip,
    shared: true
  },
  xAxis: [
    {
      gridLineColor: "#eeeeee",
      gridLineWidth: 0.5,
      title: {
        text: "DOC"
      },
      labels: {
        formatter: function() {
          return this.value;
        }
      }
    }
  ],
  yAxis: [
    {
      // Primary yAxis
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      title: {
        text: "ABW",
        style: {
          color: "#000000"
        }
      },
      opposite: false
    },
    {
      // Tertiary yAxis
      title: {
        text: "Biomass",
        style: {
          color: "#000000"
        }
      },
      labels: {
        format: "{value}",
        style: {
          color: "#000000"
        }
      },
      opposite: true
    }
  ]
};

export { abwChanGraph };
