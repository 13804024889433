var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",staticClass:"is-filters-set-absolute",attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.latestSensorDataColumnsObject,"total":_vm.total,"button":false,"tableProps":_vm.elTableProps,"addPadingToSearch":true,"columnselector_require":true,"column-selector-options":{
      keyToStoreDefaults: 'pg-latest-values',
      keyToDecideDefaultCol: 'required',
  },"selectedColumnHeadersSortable":true,"search-property":"device_code","placeHolderForSearch":"Search_device_code"},on:{"reload":_vm.handleLoadData,"loadData":_vm.handleLoadData,"cell-click":_vm.handleCellClick,"cleared":_vm.handleLoadData},scopedSlots:_vm._u([{key:"pondguard_code",fn:function(ref){
  var row = ref.row;
return [_c('el-link',{attrs:{"href":("/admin/pondguards/" + (row.data._id))}},[_vm._v("\n      "+_vm._s(row.data.code)+"\n    ")])]}},{key:"installed_date",fn:function(ref){
  var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.on_boarded_at)))+"\n  ")]}},{key:"last_communication",fn:function(ref){
  var row = ref.row;
return [(row.data.utc_last_communicated_at_status)?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v("\n      "+_vm._s(_vm.ftm__formatEmpty(
          _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
        ))+"\n    ")]):[_vm._v(_vm._s(_vm.ftm__formatEmpty(
        _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
      )))]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }